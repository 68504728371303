// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-chrome-extension-tsx": () => import("./../../../src/pages/chrome-extension.tsx" /* webpackChunkName: "component---src-pages-chrome-extension-tsx" */),
  "component---src-pages-doc-previews-tsx": () => import("./../../../src/pages/doc_previews.tsx" /* webpackChunkName: "component---src-pages-doc-previews-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-images-annotation-repository-image-tsx": () => import("./../../../src/pages/images/AnnotationRepositoryImage.tsx" /* webpackChunkName: "component---src-pages-images-annotation-repository-image-tsx" */),
  "component---src-pages-images-apple-logo-image-tsx": () => import("./../../../src/pages/images/AppleLogoImage.tsx" /* webpackChunkName: "component---src-pages-images-apple-logo-image-tsx" */),
  "component---src-pages-images-dark-mode-dark-tsx": () => import("./../../../src/pages/images/DarkModeDark.tsx" /* webpackChunkName: "component---src-pages-images-dark-mode-dark-tsx" */),
  "component---src-pages-images-dark-mode-light-tsx": () => import("./../../../src/pages/images/DarkModeLight.tsx" /* webpackChunkName: "component---src-pages-images-dark-mode-light-tsx" */),
  "component---src-pages-images-document-repository-image-tsx": () => import("./../../../src/pages/images/DocumentRepositoryImage.tsx" /* webpackChunkName: "component---src-pages-images-document-repository-image-tsx" */),
  "component---src-pages-images-document-viewer-image-tsx": () => import("./../../../src/pages/images/DocumentViewerImage.tsx" /* webpackChunkName: "component---src-pages-images-document-viewer-image-tsx" */),
  "component---src-pages-images-flashcard-review-image-tsx": () => import("./../../../src/pages/images/FlashcardReviewImage.tsx" /* webpackChunkName: "component---src-pages-images-flashcard-review-image-tsx" */),
  "component---src-pages-images-hero-image-tsx": () => import("./../../../src/pages/images/HeroImage.tsx" /* webpackChunkName: "component---src-pages-images-hero-image-tsx" */),
  "component---src-pages-images-knowledge-and-brain-organized-image-tsx": () => import("./../../../src/pages/images/KnowledgeAndBrainOrganizedImage.tsx" /* webpackChunkName: "component---src-pages-images-knowledge-and-brain-organized-image-tsx" */),
  "component---src-pages-images-reading-on-steroids-image-tsx": () => import("./../../../src/pages/images/ReadingOnSteroidsImage.tsx" /* webpackChunkName: "component---src-pages-images-reading-on-steroids-image-tsx" */),
  "component---src-pages-images-reading-progress-image-tsx": () => import("./../../../src/pages/images/ReadingProgressImage.tsx" /* webpackChunkName: "component---src-pages-images-reading-progress-image-tsx" */),
  "component---src-pages-images-statistics-image-tsx": () => import("./../../../src/pages/images/StatisticsImage.tsx" /* webpackChunkName: "component---src-pages-images-statistics-image-tsx" */),
  "component---src-pages-images-web-capture-image-tsx": () => import("./../../../src/pages/images/WebCaptureImage.tsx" /* webpackChunkName: "component---src-pages-images-web-capture-image-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-templates-blog-index-tsx": () => import("./../../../src/templates/BlogIndex.tsx" /* webpackChunkName: "component---src-templates-blog-index-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-docs-page-temp-tsx": () => import("./../../../src/templates/docs-page-temp.tsx" /* webpackChunkName: "component---src-templates-docs-page-temp-tsx" */)
}

